<template>
  <div>

    <importar-extrato :boolModal="bind.boolModalImportar" :intTipoMovimentoId="intTipoMovimentoId" :importarExtrato="importarExtrato"/>
    <ed-table
      :key="$root.$session.versao"
      strModel="Movimento/Movimento"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :config="getConfig()"
      :boolDisableTableData="!boolIniciado"
      @register="onRegister"
      @edit="onEdit"
      @search="getMovimento"
      @filter="getMovimento"
      @acaoLote="salvarAcaoLote"
    >
      <template slot="table-buttons">
        <ed-button
          v-if="intTipoMovimentoId == 1444"
          label="Importar Extrato OFX"
          :iconLeft="$utilidade.getIcone('upload')"
          color="success"
          @click="importarExtrato()"
        />
      </template>

      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data Competência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intDestinoId"
          label="Cliente/Fornecedor"
          name="intDestinoId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{}"
          :initializeItems="formFiltros.intDestinoId ? true : false"
          clearable
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayParametroStatus"
          label="Status"
          name="arrayParametroStatus"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave: 'financeiroStatus',
          }"
          :initializeItems="
            formFiltros.arrayParametroStatus &&
            formFiltros.arrayParametroStatus.length
              ? true
              : false
          "
          clearable
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayParametroCategoria"
          label="Categoria"
          name="arrayParametroCategoria"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave:
              intTipoMovimentoId == 1443
                ? 'financeiroCategoriaReceita'
                : 'financeiroCategoriaDespesa',
          }"
          :initializeItems="
            formFiltros.arrayParametroCategoria &&
            formFiltros.arrayParametroCategoria.length
              ? true
              : false
          "
          clearable
          multiple
        />


        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayParametroClassificacao"
          label="Classificação"
          name="arrayParametroClassificacao"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave:'financeiroClassificacao',
          }"
          :initializeItems="
            formFiltros.arrayParametroClassificacao &&
            formFiltros.arrayParametroClassificacao.length
              ? true
              : false
          "
          clearable
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayParametroFormaPagamento"
          label="Forma de Pagamento"
          name="arrayParametroFormaPagamento"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave: 'financeiroFormaPagamento',
          }"
          :initializeItems="
            formFiltros.arrayParametroFormaPagamento &&
            formFiltros.arrayParametroFormaPagamento.length
              ? true
              : false
          "
          clearable
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intContaBancariaId"
          label="Conta"
          name="intContaBancariaId"
          item-text="strNome"
          route="Configuracoes/ContaBancaria"
          :filters="{}"
          :initializeItems="formFiltros.intContaBancariaId ? true : false"
          clearable
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.floatValorInicial"
          label="Intervalo Valor Inicial"
          name="floatValorInicial"
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.floatValorFinal"
          label="Intervalo Valor Final"
          name="floatValorFinal"
        />

        <ed-input-auto-complete
          v-if="$root.$session.filiais.length > 1"
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.arrayFilial"
          label="Filial"
          name="arrayFilial"
          item-text="strNome"
          route="Cadastro/Filial"
          :filters="{}"
          :initializeItems="
            formFiltros.arrayFilial && formFiltros.arrayFilial.length
              ? true
              : false
          "
          clearable
          multiple
        />
      </template>

      <template slot="table-acoes">
        <v-divider></v-divider>

        <h5>Outras Atualizações</h5>
        <div class="row">
          <ed-input-auto-complete
            v-if="$root.$session.filiais.length > 1"
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.arrayFilial"
            label="Filial"
            name="arrayFilial"
            item-text="strNome"
            route="Cadastro/Filial"
            :filters="{}"
            clearable
            multiple
          />

          <ed-input-date
            class="col-12 ma-0 pa-0"
            label="Data da Competência"
            name="strDataCompentencia"
            v-model="formDataLote.strDataCompentencia"
            clearable
          />
          <ed-input-date
            class="col-12 ma-0 pa-0"
            label="Data de Vencimento"
            name="strDataVencimento"
            v-model="formDataLote.strDataVencimento"
            clearable
          />

          <ed-input-money
            class="col-12 ma-0 pa-0"
            label="Valor"
            name="floatValor"
            v-model="formDataLote.floatValor"
            clearable
          />

          <ed-input-money
            class="col-12 ma-0 pa-0"
            label="Valor Pago/Recebido"
            name="floatValorRecebido"
            v-model="formDataLote.floatValorRecebido"
            clearable
          />

          <ed-input-auto-complete
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.intParametroStatusId"
            label="Status"
            name="intParametroStatusId"
            item-text="strNome"
            route="Common/Parametro"
            :filters="{
              boolBuscarSomenteAtivo: true,
              boolBuscarParaLista: true,
              strChave: 'financeiroStatus',
            }"
            clearable
          />

          <ed-input-auto-complete
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.intParametroFormaPagamentoId"
            label="Forma de Pagamento"
            name="intParametroFormaPagamentoId"
            item-text="strNome"
            route="Common/Parametro"
            :filters="{
              boolBuscarSomenteAtivo: true,
              boolBuscarParaLista: true,
              strChave: 'financeiroFormaPagamento',
            }"
            clearable
          />

          <ed-input-auto-complete
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.intParametroCategoriaId"
            label="Categoria"
            name="intParametroCategoriaId"
            item-text="strNome"
            route="Common/Parametro"
            :filters="{
              boolBuscarSomenteAtivo: true,
              boolBuscarParaLista: true,
              strChave:
                intTipoMovimentoId == 1443
                  ? 'financeiroCategoriaReceita'
                  : 'financeiroCategoriaDespesa',
            }"
            clearable
          />

          <ed-input-auto-complete
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.intParametroClassificacaoId"
            label="Classificação"
            name="intParametroClassificacaoId"
            item-text="strNome"
            route="Common/Parametro"
            :filters="{
              boolBuscarSomenteAtivo: true,
              boolBuscarParaLista: true,
              strChave:'financeiroClassificacao'
            }"
            clearable
          />

          <ed-input-auto-complete
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.intContaBancariaId"
            label="Conta Bancária"
            name="intContaBancariaId"
            item-text="strNome"
            route="Configuracoes/ContaBancaria"
            :filters="{}"
            clearable
          />

          <ed-input-text
            class="col-12 ma-0 pa-0"
            v-model="formDataLote.strDescricao"
            label="Descrição"
            name="strDescricao"
          />
        </div>
      </template>
    </ed-table>

    <div class="row" v-if="objResumo && arrayRows.length">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <h4 v-if="intTipoMovimentoId == 1443">Resumo das Receitas</h4>
        <h4 v-if="intTipoMovimentoId == 1444">Resumo das Despesas</h4>

        <ed-simple-table disabledSearchRows disabledRegisterRows>
          <tbody>
            <template v-for="(items,strNome) in objResumo">
              <tr :key="strNome"                v-if="strNome != 'Geral' && items.length>0">
                <td colspan="2" style="background-color: #eeeeee;  font-weight: bold;">
                  {{ strNome.toUpperCase() }}
                </td>
              </tr>
               <tr :key="'items-'-index" v-for="(item,index) in items">
                <td
                  :style="
                    strNome == 'Geral'
                      ? 'font-weight:bold;color:' +
                        (intTipoMovimentoId == 1443 ? 'green' : 'red') +
                        ';font-size:15px'
                      : ''
                  "
                >
                  {{ item.strNome}}
                </td>
                <td
                  :style="
                    strNome == 'Geral'
                      ? 'font-weight:bold;color:' +
                        (intTipoMovimentoId == 1443 ? 'green' : 'red') +
                        ';font-size:15px'
                      : ''
                  "
                >
                  R$ {{ $utilidade.floatToMoney(item.floatValor) }}
                </td>
              </tr> 
            </template>
          </tbody>
        </ed-simple-table>
      </div>
    </div>
    
  </div>
</template>

<script>

import ImportarExtrato from "./partials/importar-extrato.vue";

import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputDate,
  EdButton,
  EdInputText,
  EdInputMoney,
  EdSimpleTable,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputDate,
    EdButton,
    EdInputText,
    EdInputMoney,
    ImportarExtrato,
    EdSimpleTable,
  },
  props: {
    intTipoMovimentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  watch: {
    intTipoMovimentoId() {},
  },
  mounted() {
    //this.getMovimento();
    this.initialize();
    this.boolCarregado = true;
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      boolCarregado: false,
      boolIniciado: false,
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
        },
        {
          text: "Cliente",
          sortable: true,
          value: "strCliente",
        },
        // {
        //   text: "Conta",
        //   sortable: true,
        //   value: "strContaBancaria",
        // },
        {
          text: "Data Competência",
          sortable: true,
          value: "strDataCompentencia",
        },
        {
          text: "Data Vencimento",
          sortable: true,
          value: "strDataVencimento",
        },
        {
          text: "Valor",
          sortable: true,
          value: "flaotValor",
        },
        {
          text: "Descrição",
          sortable: true,
          value: "strDescricao",
        },
        {
          text: "Forma Pagamento",
          sortable: true,
          value: "strFormaPagamento",
        },
        // {
        //   text: "",
        //   sortable: true,
        //   value: "strStatus",
        // },
      ],
      arrayRows: [],
      objPagination: null,
      objResumo: null,
      formFiltros: {
        intTipoMovimentoId: this.intTipoMovimentoId,
        intDestinoId: [],
        intParametroCategoriaId: null,
        floatValorInicial: null,
        floatValorFinal: null,
        intContaBancariaId: null,
        arrayParametroStatus: [],
        arrayParametroFormaPagamento: [],
        arrayFilial: [],
        arrayParametroCategoria: [],
        arrayParametroClassificacao:[],
        arrayDataCadastro: [
          this.$utilidade.getInicioMes(
            "DD/MM/YYYY",
            this.$utilidade.addDate(-1, null, "M", "YYYY-MM-DD")
          ),
          this.$utilidade.getFimMes(
            "DD/MM/YYYY",
            this.$utilidade.addDate(-1, null, "M", "YYYY-MM-DD")
          ),
        ],
      },
      formDataLote: {
        strDataCompentencia: null,
        strDataVencimento: null,
        intParametroStatusId: null,
        intParametroFormaPagamentoId: null,
        intContaBancariaId: null,
        strDescricao: null,
        intContaBancariaId: null,
        arrayFilial: [],
      },
      bind:{
        boolModalImportar:false
      },
      chartData: {
        labels: ["January", "February", "March"],
        datasets: [{ data: [40, 20, 12] }],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  methods: {
    initialize() {
      let objMovimentoFiltro = this.$cookie.get(
        "objMovimentoFiltro_" + this.intTipoMovimentoId
      );

      if (objMovimentoFiltro) {
        this.formFiltros = Object.assign(objMovimentoFiltro, {});
      }
    },

    onRegister() {
      this.$router.push({ name: this.$route.name + ".register" });
    },

    importarExtrato(){
      this.bind.boolModalImportar = !this.bind.boolModalImportar 
    },

    onEdit(data) {
      this.$router.push({
        name: this.$route.name + ".edit",
        params: {
          intMovimentoId: data.intId,
          intId: data.cabecalho_movimento.intId,
        },
      });
    },

    getConfig() {
      if (this.intTipoMovimentoId == 1443) {
        return {
          registerName: "Nova Receita",
          boolExibirExportar: true,
        };
      }
      return {
        registerName: "Nova Despesa",
        boolExibirExportar: true,
      };
    },

    salvarAcaoLote(ids) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .put("Movimento/Movimento", {
          boolAcaoLote: true,
          arrayId: ids,
          data: this.formDataLote,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getMovimento();
          }
        });
    },

    formatarTotalAgrupado(items) {
      let arrayData = {};

      items.forEach((element) => {
        arrayData[element.strData] = element.floatValor;
      });

      return arrayData;
    },

    exportar() {
      let formFiltros = _.cloneDeep(this.formFiltros);
      formFiltros.boolExportar = true;
    },

    downloadBoleto(item) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Movimento/Movimento", {
          boolDownloadBoleto: true,
          intId: item.intId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.href =
              "data:text/plain;base64," + objResponse.result.strBoleto;
            link.download =  objResponse.result.strNomeArquivo;
            link.click();
            document.body.removeChild(link);
          }
        });
    },
    getMovimento() {
      this.$root.$dialog.loading(true);
      this.boolIniciado = true;

      this.loading = true;
      this.arrayRows = [];
      this.objResumo = null;

      this.$cookie.set(
        "objMovimentoFiltro_" + this.intTipoMovimentoId,
        this.formFiltros,
        {
          expires: "30Y",
        }
      );

      this.$root.$request
        .get("Movimento/Movimento", this.formFiltros)
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.objPagination = objResult.result.lista;
            this.objResumo = objResult.result.resumo;
            this.loading = false;

            let arrayRows = [];

            objResult.result.lista.data.forEach((objModel) => {
              objModel.filiais = objModel.cabecalho_movimento.filiais;

              let labelStatus = [
                '<spam style="color:' +
                  (objModel.status.strValor
                    ? objModel.status.strValor.strCor
                    : "") +
                  '">' +
                  '<b>Status:</b> <i class="' +
                  (objModel.status.strValor
                    ? objModel.status.strValor.strIcone
                    : "") +
                  '"></i> ' +
                  objModel.status.strNome +
                  "</spam>",
              ];

              if (
                objModel.cabecalho_movimento &&
                objModel.cabecalho_movimento.categoria
              ) {
                labelStatus.push(
                  "<spam><b>Categoria:</b> " +
                    objModel.cabecalho_movimento.categoria.strNome +
                    "</spam>"
                );
              }

              if (
                objModel.cabecalho_movimento &&
                objModel.cabecalho_movimento.classificacao
              ) {
                labelStatus.push(
                  "<spam><b>Classificação:</b> " +
                    objModel.cabecalho_movimento.classificacao.strNome +
                    "</spam>"
                );
              }

              // if (objModel.forma_pagamento) {
              //   labelStatus.push(
              //     "<spam><b>Forma Pagamento:</b> " +
              //       objModel.forma_pagamento.strNome +
              //       "</spam>"
              //   );
              // }
              if (objModel.conta) {
                labelStatus.push(
                  '<img class="mb-2 mt-2" width="20" src="' +
                    objModel.conta.strImagem +
                    '"> ' +
                    objModel.conta.strNome
                );
              }

              if (objModel.cabecalho_movimento.boolCobrancaRecorrente) {
                labelStatus.push(
                  "<spam style='color:green' title='Essa é uma cobrança recorrente. Para configurar esse tipo de cobrança acesse o menú Financeiro > Configurações > Cobrança Recorrete'><i class='"+this.$utilidade.getIcone('subItem')+"'></i> COBRANÇA RECORRENTE</spam>"
                );
              }

              if (objModel.cabecalho_movimento.boolCobrancaMensal) {
                labelStatus.push(
                  "<spam style='color:green' title='Essa é uma cobrança automática gerado mensalmente. Para configurar esse tipo de cobrança acesse o cadastro do cliente.'><i class='"+this.$utilidade.getIcone('subItem')+"'></i> COBRANÇA MENSAL</spam>"
                );
              }

              let item = {
                intId: objModel.intId,
                strDescricao: objModel.strDescricao,
                strFormaPagamento: objModel.forma_pagamento
                  ? objModel.forma_pagamento.strNome
                  : null,
                strDataVencimento: this.$utilidade.toDate(
                  objModel.strDataVencimento,
                  true
                ),
                strDataCompentencia: this.$utilidade.toDate(
                  objModel.strDataCompentencia,
                  true
                ),
                strCliente:
                  "<b>" +
                  objModel.cabecalho_movimento.destino.strNome +
                  "</b>" +
                  "<br>" +
                  labelStatus.join("<br>"),
                flaotValor: this.$utilidade.floatToMoney(
                  (objModel.floatValorRecebido
                    ? objModel.floatValorRecebido
                    : objModel.floatValor) +
                    objModel.floatValorAcrescimento -
                    objModel.floatValorDesconto
                ),
                buttons: [],
                _item: objModel,
              };

              if (
                objModel.forma_pagamento.strChave ==
                  "financeiroFormaPagamentoBoleto" &&
                [
                  "pagamentoStatusBoletoGerado",
                  "financeiroStatusBoletoGerado",
                ].indexOf(objModel.status.strChave) != -1
              ) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("pdf"),
                  color: "warning",
                  title: "Download do Boleto",
                  click: this.downloadBoleto,
                });
              }
              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
