<template>
  <div>
    <ed-modal v-model="boolModal" width="95%" :scrollable="true">
      <div slot="title">
        Importar Extrato OFX
      </div>

      <div class="" slot="content">

        <ed-form :formData="formData" :handlerSave="formDataExtrato ? salvar : getExtrato" :labelButton="formDataExtrato ? 'Salvar' : 'Validar Extrato'">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
              <span class="form-title">Informações do arquivo</span>

              <div class="row">
                <ed-input-file
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  v-model="formData.arquivo"
                  label="Arquivo (Somente arquivo .OFX)"
                  accept=".ofx"
                  rules="required"
                />
              </div>

              <div v-for="(objDados,index) in formDataExtrato" :key="index">
                <div class="row">
                    <ed-input-select
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                      label="Conta Bancária"
                      name="intNumeroAgendamentoPorHora"
                      v-model="objDados.intContaBancariaId"
                      :items="bind.arrayContaBancaria"
                      rules="required"
                    />

                    <ed-input-date
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                      label="Data Inicial"
                      name="strDataInicio"
                      v-model="objDados.strDataInicio"
                    />

                    <ed-input-date
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                      label="Data Final"
                      name="strDataFinal"
                      v-model="objDados.strDataFinal"
                    />


                    <ed-input-date
                      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                      label="Data Competência"
                      name="strDataCompetencia"
                      v-model="objDados.strDataCompetencia"
                    />

                    <ed-simple-table
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      :config="{ registerName: 'Inserir novo exame' }"
                      disabledSearchRows
                      disabledRegisterRows
                    >
                      <thead>
                        <tr>
                          <th>Contato no Extrato</th>
                          <th>Contato no Sistema</th>
                          <th>Categoria</th>
                          <th>Serviço</th>
                          <th>Forma Pagamento</th>
                          <th>Status</th>
                          <th>Data</th>
                          <th>Valor</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(objMovimento,j) in objDados.movimentos" :key="j">
                            <td align="left" style="padding: 5px;">
                              {{ objMovimento.strNome }} 
                              <span class="ed-full-width" style="color:#c98650">{{ objMovimento.strDescricao }}</span>
                            </td>
                            <td align="left" style="padding-top: 10px;padding-bottom: 5px;">
                              <ed-input-auto-complete
                                v-model="objMovimento.intDestinoId"
                                :name="'intDestinoId'+j"
                                item-text="strNome"
                                route="Cadastro/CadastroGeral"
                                :initializeItems="objMovimento.intDestinoId ? true : false"
                                clearable
                                hideDetails
                              />
                            </td>
                            <td align="left" style="padding-top: 10px;padding-bottom: 5px;">
                              <ed-input-select
                                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                :name="'intParametroCategoriaId'+j"
                                v-model="objMovimento.intParametroCategoriaId"
                                :items="bind.arrayCategoria"
                                rules="required"
                                hideDetails
                              />

                            </td>
                            <td align="left" style="padding-top: 10px;padding-bottom: 5px;">
                              <ed-input-select
                                class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                :name="'intParametroServicoId'+j"
                                v-model="objMovimento.intParametroServicoId"
                                :items="bind.arrayServico"
                                rules="required"
                                hideDetails
                              />
                            </td>
                            <td>
                              <ed-input-select
                              class=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                label="Forma de Pagamento"
                                name="intParametroFormaPagamentoId"
                                v-model="objMovimento.intParametroFormaPagamentoId"
                                :items="bind.arrayFormaPagamento"
                                rules="required"
                                hideDetails
                              />

                            </td>
                            <td align="left" style="padding-top: 10px;padding-bottom: 5px;">
                              <ed-input-select
                                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                :name="'intParametroStatusId'+j"
                                v-model="objMovimento.intParametroStatusId"
                                :items="bind.arrayStatus"
                                rules="required"
                                hideDetails
                              />

                            </td>
                            <td align="left" style="padding: 5px;">
                              {{ objMovimento.strDataCadastro }}
                            </td>
                            <td align="left" style="color:red">
                              R$ {{ objMovimento.strValor }}
                            </td>
                            <td  align="left">
                                <ed-button icon :iconLeft="$utilidade.getIcone('deletar')" color="red" title="Deletar item do extrato" @click="deletar(index,j)"/>
                            </td>
                          </tr>
                      </tbody>
                    </ed-simple-table>

                </div>


              </div>



            </div>
          </div>
        </ed-form>
      </div>

    </ed-modal>
  </div>
</template>

<script>
import {
  EdForm,
  EdModal,
  EdButton,
  EdSimpleTable,
  EdInputFile,
  EdInputSelect,
  EdInputAutoComplete,
  EdInputDate
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    boolModal: {
      type: [Boolean],
      required: true,
      default: function () {
        return false;
      },
    },
    intTipoMovimentoId: {
      type: [Number],
      required: true,
      default: function () {
        return 1444;
      },
    },
    importarExtrato:{
      type: [Function],
      required: true,
      default: function () {
        return 1444;
      },
    }
  },
  components: {
    EdForm,
    EdModal,
    EdButton,
    EdSimpleTable,
    EdInputFile,
    EdInputSelect,
    EdInputAutoComplete,
    EdInputDate
  },
  inject: [
  ],
  mounted() {
    this.initialize()
  },
  beforeDestroy() { },
  created() { },
  data() {
    return {
      formData: {
        intTipoMovimentoId:this.intTipoMovimentoId,
        boolPrepararExtrato:true,
        arquivo:null
      },
      formDataExtrato:null,
      bind:{
        arrayContaBancaria:[],
        arrayCategoria:[],
        arrayStatus:[],
        arrayServico:[],
        arrayFormaPagamento:[]
      }
    };
  },
  watch: {
  },
  computed: {},
  methods: {
    initialize() { 


      Promise.all([
        this.$root.$request.get("Configuracoes/ContaBancaria"),
        this.$root.$request.get("Common/Parametro",{strChave: "financeiroCategoriaDespesa"}),
        this.$root.$request.get("Common/Parametro",{strChave: "financeiroStatus"}),
        this.$root.$request.get("Common/Parametro",{strChave: "financeiroServico"}),
        this.$root.$request.get("Common/Parametro",{strChave: "financeiroFormaPagamento"})
      ]).then(([arrayContaBancaria,arrayCategoria,arrayStatus,arrayServico,arrayFormaPagamento]) => {
        this.bind.arrayContaBancaria = arrayContaBancaria.result.data
        this.bind.arrayCategoria = arrayCategoria.result.parametros
        this.bind.arrayStatus = arrayStatus.result.parametros
        this.bind.arrayServico = arrayServico.result.parametros
        this.bind.arrayFormaPagamento = arrayFormaPagamento.result.parametros
      })

    },

    deletar(indexItem,indexExtrato){
      this.$root.$dialog
        .confirm("Tem certeza que deseja remover esse item do extrato?")
        .then(() => {
          this.formDataExtrato[indexItem].movimentos.splice(indexExtrato, 1);
        });
    },

    salvar() {

      this.$root.$dialog
        .confirm("Tem certeza que deseja importar o extrato?")
        .then(() => {
          this.$root.$dialog.loading(true);

          this.formDataExtrato.intTipoMovimentoId = this.intTipoMovimentoId;
          this.$root.$request
            .get("Movimento/CabecalhoMovimento", {boolImportarExtrato:true,dados:this.formDataExtrato})
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if(objResponse.status == 200) {
                this.$root.$message.success("Extrato importado com sucesso");
                this.formDataExtrato = null
                this.importarExtrato()
              }
            });
        });

    },

    getExtrato() {
      this.$root.$dialog.loading(true);

      console.log('this.formData',this.formData)
      this.formData.intTipoMovimentoId = this.intTipoMovimentoId;
      this.$root.$request
        .get("Movimento/CabecalhoMovimento", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if(objResponse.status == 200) {
            this.formDataExtrato = objResponse.result
          }
        });
    },

  },
};
</script>
